<template>
	<div>
		<search-header></search-header>
		<div class="container">
			<div class="row mt-3">
				<b-alert class="w-100" show variant="warning">
					登记需求后，平台将会为您推荐匹配的土地或农机具，您也可“个人中心-我的需求-查看信息”浏览系统推送的土地或农机具供应信息！
				</b-alert>
			</div>

			<div class="row rounded mb-3">
				<b-card class="w-100" no-body>
					<b-tabs pills card>
						<b-tab title="土地" active>
							<div class="clearfix">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>标题</span>
								</div>
								<div class="float-left">
									<b-form-tags :value="tag" placeholder="" class="mb-2"
										disabled></b-form-tags>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>流转类型</span>
								</div>
								<div class="float-left">
									<b-radio-group v-model="para.needsType" @change="onNeedsTypeChange">
										<b-radio name="some-radios" value="1">求租</b-radio>
										<b-radio name="some-radios" value="2">求购</b-radio>
									</b-radio-group>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>区域</span>
								</div>
								<div class="float-left areabox box">
									<input @click.stop="isAreaShow = !isAreaShow" :value="areaValue"  class="form-control" type="text" placeholder="城市选择" readonly="readonly">
									<em>
										<b-icon icon="chevron-down"></b-icon>
									</em>
									<city v-model="isAreaShow" @onChoose="onChooseArea"></city>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 mt-1" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>土地类型</span>
								</div>
								<div class="float-left">
									<div class="col" style="width:970px">
										<div class="row screen">
											<a href="javascript:;" @click="onClassClick(m)" :class="{ 'active' : classId == m.id }" v-for="m,i in useLandList" :key="i" class="mr-3 px-2 py-1">{{m.name}}</a>
										</div>
										<div class="row screen mt-2">
											<a href="javascript:;" @click="onUlClick(m)" :class="{ 'active' : para.classId == m.code }" v-for="m,i in getChildUseLandList" :key="i" class="mr-3 px-2 py-1">{{m.name}}</a>
										</div>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span>流转年限</span>
								</div>
								<div class="float-left">
									<input v-model="para.year" class="form-control mr-sm-2" maxlength="3" type="number">
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>面积</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input v-model="para.measureArea" @change="maChange" class="float-left form-control width-186" maxlength="10" type="number">
										<span class="m-2">{{currentUtil.acreage}} {{ currentUtil.acreage == '亩' ? '（1亩≈666.7平米）' : '（1平方米≈0.0015亩）'}}</span>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span>期望价格</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input v-model="para.minPrice" class="float-left form-control width-80" maxlength="10" type="number">
										<span class="float-left mr-2 ml-2">-</span>
										<input v-model="para.maxPrice" class="float-left form-control width-80" maxlength="10" type="number">
										<span class="m-2">{{currentUtil.price}}</span>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span>补充说明</span>
								</div>
								<div class="float-left">
									<textarea v-model="para.content" class="form-control mr-sm-2"
										style="width: 400px;height: 140px;"></textarea>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>联系人</span>
								</div>
								<div class="float-left">
									<input v-model="para.contacts" class="form-control mr-sm-2" maxlength="10" type="text">
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3 width-100">
									<span class="text-danger mr-1">*</span><span>联系电话</span>
								</div>
								<div class="float-left">
									<input v-model="para.contactNumber" class="form-control mr-sm-2" maxlength="11" type="text">
								</div>
							</div>
							<div class="clearfix mt-3">
								<b-button @click="addNeeds" variant="success"
									style="width: 229px;margin-left: 116px;">立即发布</b-button>
							</div>
						</b-tab>
						<!-- <b-tab title="农机具">
							<div class="clearfix">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>标题</span>
								</div>
								<div class="float-left">
									<b-form-tags input-id="tags-basic" v-model="value" placeholder="" class="mb-2"
										disabled></b-form-tags>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>流转类型</span>
								</div>
								<div class="float-left">
									<b-radio-group>
										<b-radio name="some-radios" value="1">求租</b-radio>
										<b-radio name="some-radios" value="2">求购</b-radio>
									</b-radio-group>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>区域</span>
								</div>
								<div class="float-left">
									<b-dropdown id="dropdown-1" text="请选择城市区域" variant="outline-success">
										<b-dropdown-item>土地知识</b-dropdown-item>
										<b-dropdown-item>土地交易</b-dropdown-item>
										<b-dropdown-item>政策法规</b-dropdown-item>
										<b-dropdown-item>农业相关</b-dropdown-item>
										<b-dropdown-item>其他问题</b-dropdown-item>
									</b-dropdown>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>农机具</span>
								</div>
								<div class="float-left">
									<b-dropdown id="dropdown-1" text="请选择农用机械" variant="outline-success">
										<b-dropdown-item>拖拉机</b-dropdown-item>
										<b-dropdown-item>挖掘机</b-dropdown-item>
										<b-dropdown-item>播种机</b-dropdown-item> 
									</b-dropdown>
								</div>
							</div>
							<div class="clearfix mt-3" style="height: 38px; line-height: 38px;">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span>租用</span>
								</div>
								<div class="float-left">
									<input class="float-left form-control" maxlength="10" type="number"
										style="width: 80px;">
									<span class="m-2">小时</span>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>用地面积</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input class="float-left form-control" maxlength="10" type="number"
											style="width: 120px;">
										<span class="float-left mr-2 ml-2">-</span>
										<input class="float-left form-control" maxlength="10" type="number"
											style="width: 120px;">
										<span class="m-2">平方米（如使用播种机播种1000-2000平方米）</span>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span>期望价格</span>
								</div>
								<div class="float-left">
									<div class="clearfix" style="height: 38px; line-height: 38px;">
										<input class="float-left form-control" maxlength="10" type="number"
											style="width: 120px;">
										<span class="float-left mr-2 ml-2">-</span>
										<input class="float-left form-control" maxlength="10" type="number"
											style="width: 120px;">
										<span class="m-2">元</span>
									</div>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>补充说明</span>
								</div>
								<div class="float-left">
									<textarea class="form-control mr-sm-2"
										style="width: 400px;height: 140px;"></textarea>
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>联系人</span>
								</div>
								<div class="float-left">
									<input class="form-control mr-sm-2" maxlength="10" type="text">
								</div>
							</div>
							<div class="clearfix mt-3">
								<div class="float-left text-right mr-3" style="width: 100px;">
									<span class="text-danger mr-1">*</span><span>联系电话</span>
								</div>
								<div class="float-left">
									<input class="form-control mr-sm-2" maxlength="20" type="text">
								</div>
							</div>
							<div class="clearfix mt-3">
								<b-button class="form-control " variant="success"
									style="width: 229px;margin-left: 116px;">提交问题</b-button>
							</div>
						</b-tab> -->
					</b-tabs>
				</b-card>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
	import Vue from 'vue';
	import city from "@/components/city/city.vue";
	export default {
		components: {
			city
		},
		data() {
			return {
				tag: ['','','',''],
				isAreaShow: false,
				areaValue: '',
				classId: '',
				useLandList:[],
				priceUnitList:[],
				acreageUnitList:[],
				metreArray: ['商业及住宅','公共用地', '农房'],
				currentUtil: {
					price: '元/亩/年',
					acreage: '亩'
				},
				para: {
					priceUnit: '',
					acreageUnit: '',
                    area: '',
                    classId: '',
                    contactNumber: '',
                    contacts: '',
                    content: '',
                    maxPrice: '',
                    measureArea: '',
                    minPrice: '',
                    needsType: '',
                    title: '',
                    year: ''
				}
			}
		},
		computed: {
			getChildUseLandList() {
				let c = this.useLandList.find(x=> x.id == this.classId);
				if (c)  {
					return c.children;
				} 
				return []
			},
		},
        methods: {
			onClassClick(m) {
				this.classId = m.id;
				if(this.metreArray.includes(m.name)) {
					this.currentUtil.acreage = '平方米';
				} else {
					this.currentUtil.acreage = '亩';
				}
				if(this.para.needsType == 2) {
					this.currentUtil.price = '万元'
				} else {
					this.currentUtil.price = '元/'+this.currentUtil.acreage.replace('方','')+'/年'
				}

				this.maChange();
			},
			onUlClick(m) {
				this.para.classId = m.code;
				Vue.set(this.tag,2,m.name);
				this.autoFillTitle();
			},
			onNeedsTypeChange(value) {
				Vue.set(this.tag, 0, value == 1 ? '求租' : '求购');
				if(value == 2) {
					this.currentUtil.price = '万元'
				} else {
					this.currentUtil.price = '元/'+ this.currentUtil.acreage.replace('方','') +'/年'
				}
				this.autoFillTitle();
			},
			maChange() { 
				Vue.set(this.tag,3,this.para.measureArea > 0 ?(this.para.measureArea + this.currentUtil.acreage) : '');
				this.autoFillTitle();
			},
			onChooseArea(a) {
				this.areaValue = a.province.name + (a.city.name == '市辖区' ? '' : a.city.name ) + a.area.name;
				Vue.set(this.tag, 1, (a.city.name == '市辖区' ? a.province.name : a.city.name ) + a.area.name);
				this.para.area = a.area.code; 
				this.isAreaShow = false;
				this.autoFillTitle();
			},
			autoFillTitle() {
				this.para.title = this.tag.join(' ');
			},
			verify() {
				if(this.para.classId == '' || this.para.area == '' || this.para.measureArea.trim() == '' || this.para.needsType== '' ||  this.para.title.trim() == '' || this.para.contacts.trim() == '' || this.para.contactNumber.trim() == '') {
					this.$model.toast('带*符号为必填项，请填写必填信息才能发布！',2000)
					return false;
				} else {
					if (!this.$test.mobile(this.para.contactNumber)) {
						this.$model.toast('请输入正确的联系电话',2000)
						return false
					}
				}
				if(this.minPrice >= 0 && this.maxPrice > 0) {
					if(this.para.maxPrice <= this.para.minPrice) {
						this.$model.toast('期望价格区间最大价格必须大于最小价格',2000)
						return false;
					}
				}
				let p = this.priceUnitList.find(x=>x.text == this.currentUtil.price);
				let a = this.acreageUnitList.find(x=>x.text == this.currentUtil.acreage);
				if(p) {
					this.para.priceUnit = p.detailValue
				}
				if(a) {
				 	this.para.acreageUnit =	a.detailValue;
				}
				return true;
			},
			getUseLand() {
				this.$http.post(this.$api.dict.getLandUseType, {})
				.then((res) => {
					if (res.code == 1) {
						this.useLandList = res.result;
					}
				});
			},
			getUnit() {
				this.$http.post(this.$api.dict.findDickTypeName, {
					typeName: 'PRICE_UNIT'
				})
				.then((res) => {
					if (res.code == 1) {
						this.priceUnitList = res.result[0].dictionaryDetails;
					}
				});

				this.$http.post(this.$api.dict.findDickTypeName, {
					typeName: 'ACREAGE_UNIT'
				})
				.then((res) => {
					if (res.code == 1) {
						this.acreageUnitList = res.result[0].dictionaryDetails;
					}
				});
			},
            addNeeds() {
				if(this.verify()) {
					this.$http.post(this.$api.user.addNeeds,this.para).then(res => {
						if(res.code  == 1) {
							this.$model.toast('发布成功，请等待平台审核',2000);
							setTimeout(() => {
								this.$router.go(0);
							}, 2000);
						} else {
							this.$model.toast(res.reason,2000);
						}
					}).catch(error => {
						this.$model.toast(error.reason,2000);
					})
				}
            }
        },
        created() {
            this.getUseLand();
			this.getUnit();
        }
	}
</script>

<style scoped>
	.screen .active {
		background: #00cc00;
		color: #FFFFFF;
		border-radius: 6px;
	}
</style>
